
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <form @submit.prevent="updateShopInvoiceItems" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="shop_invoices" v-model="shop_invoice_items.shop_invoice_id" dense  filled outlined  item-text="shop_invoice_id"
                                    item-value="shop_invoice_id" :return-object="false" :label="$store.getters.language.data.shop_invoices.shop_invoice_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="shop_items" v-model="shop_invoice_items.shop_item_id" dense  filled outlined  item-text="shop_item_id"
                                    item-value="shop_item_id" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_id">
                        </v-select>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['pending','complete','returned']" v-model="shop_invoice_items.shop_invoice_item_status" dense  filled outlined  item-text="shop_invoice_item_status"
                                    item-value="shop_invoice_item_status" :return-object="false" :label="$store.getters.language.data.shop_invoice_items.shop_invoice_item_status">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="users" v-model="shop_invoice_items.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.shop_invoice_items.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/shop_invoice_items.request.js'
    export default {
        data() {
            return {
                shop_invoice_items: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    shop_invoices(){
                        return this.$store.getters.shop_invoices_list
                    },
                    
                    shop_items(){
                        return this.$store.getters.shop_items_list
                    },
                    
                    users(){
                        return this.$store.getters.users_list
                    },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneShopInvoiceItems()
        },
        methods: {
            getOneShopInvoiceItems() {
                this.loading = true
                requests.getOneShopInvoiceItems(this.id).then(r =>{
                    if (r.status == 200) {
                        this.shop_invoice_items = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read ShopInvoiceItems',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read ShopInvoiceItems',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateShopInvoiceItems() {
                this.loading = true
                requests.updateShopInvoiceItems(this.id,this.shop_invoice_items).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'ShopInvoiceItems Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    